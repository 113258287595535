// ---------------------------------------------------------------------------------------------------------------------
// Mixin: device
// ---------------------------------------------------------------------------------------------------------------------
$desktop-width: $page-width + 2 * ($layout-padding);

@mixin mobile($extra-width: 0) {
	@media (max-device-width: $desktop-width + $extra-width) {
		@content
	}
}

@mixin desktop($extra-width: 0) {
	@media (min-device-width: $desktop-width + $extra-width - 1) {
		@content
	}
}
