@import './theme';
@import './mixin/device';
@import './mixin/theme';

// ---------------------------------------------------------------------------------------------------------------------
// Body:
// ---------------------------------------------------------------------------------------------------------------------

html, body {
	margin: 0;
	padding: 0;
	height: 100%;

	font-size: 1em;
	font-family: 'Open Sans', 'Roboto', 'arial', sans-serif;

	@include themed((
		background: primary-background,
		color:      primary-text
	));
}

body > .page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	> .page {
		flex: 1 0 auto;
	}

	> footer {
		flex-shrink: 0;
	}
}

body > .page-wrapper > .page {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	justify-content: center;
	align-content: flex-start;

	padding-top: $layout-header-height;
}

a {
	transition: color $animation-speed;
}

.flex-spacer {
	flex-grow: 10;
}

.centered,
.centered-squash {
	display: flex;
	justify-content: center;
}

.centered > * {
	flex: 1 0 auto;
}

// ---------------------------------------------------------------------------------------------------------------------
// Navigation:
// ---------------------------------------------------------------------------------------------------------------------

nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	display: flex;
	flex-direction: row;
	box-sizing: border-box;

	height: $layout-header-height;
	z-index: $layout-header-z;

	border-bottom: transparent 1px solid;
	box-shadow: theme-get(header-shadow) 0 1px 1px;

	@include themed((
		color:        header-text,
		background:   header-background,
		border-color: header-border
	));

	// Profile picture.
	.profile-picture {
		width: $layout-header-height - ($layout-header-padding * 2) - 1;
		height: $layout-header-height - ($layout-header-padding * 2) - 1;
	}
}

// Nav icons.
nav {
	.nav-icon {
		font-size: $layout-header-height - ($layout-header-padding * 2) - $layout-header-padding - 5px;
		border: none;
	}

	a.nav-icon,
	a:link.nav-icon {
		@include themed((color: header-link-icon));

		&:hover {
			@include themed((color: header-link-hover));
		}
	}
}

// Nav images.
nav img {
	width: $layout-header-height - ($layout-header-padding * 2) - 1;
	height: $layout-header-height - ($layout-header-padding * 2) - 1;
}

// Nav links.
nav {
	a, a:link {
		text-decoration: none;
		@include themed((color: header-link-text));
	}

	a:hover {
		@include themed((color: header-link-hover));
	}
}

// Nav spacer.
nav {
	.nav-spacer {
		flex-grow: 1;
		border: none;
	}
}

// Nav sections.
nav {

	> .nav-section {
		padding: $layout-header-padding;
	}

	> .nav-fixed {
	}

	> .nav-flex {
		flex-grow: 1;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding-left: 0;

		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		> * {
			flex-shrink: 0;
		}
	}
}

// Nav link separators.
nav .nav-links {
	* {
		display: inline-block;
		height: 100%;
		line-height: $layout-header-height - ($layout-header-padding * 2);
	}

	a {
		padding: 0 5px;
	}

	&:not(.links-profile) {
		a {
			border-left: transparent 1px solid;
			@include themed((border-color: header-border));
		}

		a:last-child {
			border-right: transparent 1px solid;
			@include themed((border-color: header-border));
		}
	}
}

// ---------------------------------------------------------------------------------------------------------------------
// Footer:
// ---------------------------------------------------------------------------------------------------------------------

footer {
	display: flex;
	flex-direction: row;

	border-top: transparent 1px solid;
	padding: $layout-footer-padding;
	font-size: 0.85em;

	@include themed((
		color:        footer-text,
		border-color: footer-border
	));

	// Footer spacer.
	> .spacer {
		flex-grow: 1;
		border: none;
	}

	// Footer items.
	> * {
		margin: 0 5px;
	}

	// Footer links.
	a,
	a:link {
		@include themed((color: footer-link-text));
		text-decoration: none;
	}

	a:hover {
		@include themed((color: footer-link-text-hover));
	}

	.copyright a,
	.copyright a:link {
		@include themed((color: footer-text));
	}
}

// ---------------------------------------------------------------------------------------------------------------------
// Page:
// ---------------------------------------------------------------------------------------------------------------------

main {
	flex: 1 1 auto;

	margin-top: $layout-top-margin;
	padding: $layout-padding;

	.page.seamless & {
		margin-top: 0;
	}

	@include mobile($layout-top-margin * 2) {
		margin-top: 0;
	}
}

// Section Separators.
.section-spacer {
	height: 5em;
}

.section-spacer + .section > .section-heading,
.section.section-box .section-heading {
	box-shadow: theme-get(section-shadow) 0px -1px 5px;
	border: transparent 1px solid;

	margin: $layout-padding (-$layout-padding);
	padding: 12px 1em;

	@include themed((
		border-color: section-border,
		background: section-background
	));

	h1,
	h2,
	p {
		margin: 0;
	}
}

.section-t .section-heading > * {
	display: inline-block;

	font-size: 1.1em;
	font-weight: normal;

	border-bottom: transparent 1px solid;
	margin-bottom: $layout-section-margin;
	padding-bottom: 3px;

	@include themed((
		color: header-text-light,
		border-color: header-text-light,
	))
}

// Headings.
h1 {
	margin: 0 0 4px 0;
	font-size: 2em;
	font-weight: bold;
}

// Text.
p, div.paragraph {
	margin: 0 0 12px 0;

	&.centered-text {
		text-align: center;
	}
}

// Links.
a,
a:link {
	text-decoration: none;
	@include themed((color: link-text))
}

a:hover {
	text-decoration: underline;
	@include themed((color: link-text-hover))
}

// Desktop margins.
main:not(.full) {
	@include desktop {
		max-width: $page-width;
	}
}

// Columns.
.column-2 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	justify-content: center;
	text-align: center;

	> * {
		padding: $layout-padding / 2;
		flex: 1 0 0;
	}
}